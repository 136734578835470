.btn-primary-panel {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #007bff;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary-panel:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary-panel:focus, .btn-primary-panel.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary-panel:disabled, .btn-primary-panel.disabled {
  color: #fff;
  background-color: #007bff;
  border-color: transparent;
}


.btn-secondary-panel {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #6c757d;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-secondary-panel:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary-panel:focus, .btn-secondary-panel.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary-panel:disabled, .btn-secondary-panel.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: transparent;
}

.btn-success-panel {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #28a745;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-success-panel:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success-panel:focus, .btn-success-panel.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success-panel:disabled, .btn-success-panel.disabled {
  color: #fff;
  background-color: #28a745;
  border-color: transparent;
}

.btn-danger-panel {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #dc3545;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-danger-panel:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger-panel:focus, .btn-danger-panel.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger-panel:disabled, .btn-danger-panel.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: transparent;
}

.btn-warning-panel {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  border: 1px solid transparent;
}

.btn-warning-panel:hover {
  color: #fff;
  background-color: #ffca2c;
  border-color: #ffca2c;
}

.btn-warning-panel:focus, .btn-warning-panel.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning-panel.disabled, .btn-warning-panel:disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning-panel:not(:disabled):not(.disabled):active, .btn-warning-panel:not(:disabled):not(.disabled).active,
.show > .btn-warning-panel.dropdown-toggle {
  color: #fff;
  background-color: #ffca2c;
  border-color: #ffca2c;
}


.btn-info-panel {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-info-panel:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info-panel:focus, .btn-info-panel.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info-panel:disabled, .btn-info-panel.disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
