
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "/src/assets/scss/cdkDragDrop";
@import "/src/assets/scss/customBootstrap";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ngx-admin-cms-primary: mat.define-palette(mat.$indigo-palette);
$ngx-admin-cms-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// The warn palette is optional (defaults to red).
$ngx-admin-cms-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ngx-admin-cms-theme: mat.define-light-theme((
  color: (
    primary: $ngx-admin-cms-primary,
    accent: $ngx-admin-cms-accent,
    warn: $ngx-admin-cms-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ngx-admin-cms-theme);

/* You can add global styles to this file, and also import other style files */

// ng-select2
@import "~@ng-select/ng-select/themes/default.theme.css";

// flatpicker
@import '~flatpickr/dist/flatpickr.css';

// Rating
.stars {
    font-size: 30px;
    color: var(--bs-gray);
}

.star {
    position: relative;
    display: inline-block;
}

.star_fill {
    color: var(--bs-gray);
}

.full {
    color: #b0c4de;
}

.half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: var(--bs-gray);
}

.filled {
    color: #1e90ff;
}

.bad {
    color: #deb0b0;
}

.filled.bad {
    color: #ff1e1e;
}

.halfHeart {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: red;
}


// File Upload
.dropzone_sec {
    border: 2px dashed #e2e5e8 !important;
}

.dropzone .dz-message {
    font-size: 24px;
    min-height: 150px !important;
    border: none !important;
    background: transparent !important;
    color: var(--bs-gray-400) !important;
}

.dropzone {
    border: 2px dashed var(--bs-border-color) !important;
}

dropzone>.dropzone.dz-wrapper {
    border: none !important;
}

// Range Slider
.ng5-slider .ng5-slider-pointer {
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    top: -8px !important;
    background-color: #5b73e8 !important;
    z-index: 3;
    border-radius: 16px;
}

.ng5-slider .ng5-slider-pointer:after {
    display: none !important;
}

.ng5-slider .ng5-slider-selection {
    background: #5b73e8 !important;
}

body[data-bs-theme=dark] {
    .dropzone {
        background: transparent;
    }

    .ng5-slider .ng5-slider-bar {
        background: var(--bs-gray-300);
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent !important;
    }

    // Select 2
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        background-color: #253a55 !important;
        color: #8495ab !important;
    }

    .ng-select .ng-select-container {
        background-color: var(--bs-input-bg) !important;
        border: 1px solid var(--bs-input-border-color);
        color: var(--bs-body-color);
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background-color: var(--bs-gray-100);
        color: var(--bs-body-color);
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        background-color: var(--bs-input-bg);
        color: var(--bs-body-color);
    }

    .ng-dropdown-panel {
        border: 1px solid var(--bs-gray-100);
    }

    // Sweet alert
    .swal2-popup {
        background: var(--bs-input-bg);
        color: var(--bs-body-color);
    }
}

// scroll bar
.simplebar-scrollbar:before {
    width: 100%;
    background: #a2adb7 !important;
}


// timeline

.hori-timeline{
    .owl-nav{
        .owl-prev,.owl-next{
            background-color: rgba(91,115,232,.25)!important;
            color: #5b73e8!important;
            border-radius: 50%;
        }
    }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

